<template>
    <div class="guests-number">
        <div class="radio-buttons">
        <input v-model="picked" v-on:click="gpicked(0)" type="radio" id="guest0" name="guests" value="0" checked>
        <label for="guest0">Без гостей</label>

        <input v-model="picked"  v-on:click="gpicked(1)" type="radio" id="guest1" name="guests" value="1">
        <label for="guest1"><span class="guest-span">+1</span></label>
    
        <input v-model="picked"  v-on:click="gpicked(2)" type="radio" id="guest2" name="guests" value="2">
        <label for="guest2"><span class="guest-span">+2</span></label>
    
        <input v-model="picked"  v-on:click="gpicked(3)" type="radio" id="guest3" name="guests" value="3">
        <label for="guest3"><span class="guest-span">+3</span></label>
        </div>
    </div> 
</template>

<script>
export default {
  name: 'GuestsCount',
  data() {
    return{
      picked: 0
    }
  },
  methods:{
    gpicked(value) {
      this.picked = value
      this.$emit('guestsPicked',this.picked);
    }
  }
}
</script>

<style scoped>
/* Ваши стили для ContactForm */
</style>
