/**
 * @fileoverview gRPC-Web generated client stub for teetimes
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.teetimes = require('./booking_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teetimes.BookingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teetimes.BookingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teetimes.AvailableRequest,
 *   !proto.teetimes.AvailableResponse>}
 */
const methodDescriptor_BookingService_SendAvailable = new grpc.web.MethodDescriptor(
  '/teetimes.BookingService/SendAvailable',
  grpc.web.MethodType.UNARY,
  proto.teetimes.AvailableRequest,
  proto.teetimes.AvailableResponse,
  /**
   * @param {!proto.teetimes.AvailableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teetimes.AvailableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teetimes.AvailableRequest,
 *   !proto.teetimes.AvailableResponse>}
 */
const methodInfo_BookingService_SendAvailable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teetimes.AvailableResponse,
  /**
   * @param {!proto.teetimes.AvailableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teetimes.AvailableResponse.deserializeBinary
);


/**
 * @param {!proto.teetimes.AvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teetimes.AvailableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teetimes.AvailableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teetimes.BookingServiceClient.prototype.sendAvailable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teetimes.BookingService/SendAvailable',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendAvailable,
      callback);
};


/**
 * @param {!proto.teetimes.AvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teetimes.AvailableResponse>}
 *     Promise that resolves to the response
 */
proto.teetimes.BookingServicePromiseClient.prototype.sendAvailable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teetimes.BookingService/SendAvailable',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendAvailable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teetimes.BookingRequest,
 *   !proto.teetimes.BookingResponse>}
 */
const methodDescriptor_BookingService_SendBooking = new grpc.web.MethodDescriptor(
  '/teetimes.BookingService/SendBooking',
  grpc.web.MethodType.UNARY,
  proto.teetimes.BookingRequest,
  proto.teetimes.BookingResponse,
  /**
   * @param {!proto.teetimes.BookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teetimes.BookingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teetimes.BookingRequest,
 *   !proto.teetimes.BookingResponse>}
 */
const methodInfo_BookingService_SendBooking = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teetimes.BookingResponse,
  /**
   * @param {!proto.teetimes.BookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teetimes.BookingResponse.deserializeBinary
);


/**
 * @param {!proto.teetimes.BookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teetimes.BookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teetimes.BookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teetimes.BookingServiceClient.prototype.sendBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teetimes.BookingService/SendBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendBooking,
      callback);
};


/**
 * @param {!proto.teetimes.BookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teetimes.BookingResponse>}
 *     Promise that resolves to the response
 */
proto.teetimes.BookingServicePromiseClient.prototype.sendBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teetimes.BookingService/SendBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teetimes.PayRequest,
 *   !proto.teetimes.PayResponse>}
 */
const methodDescriptor_BookingService_SendPay = new grpc.web.MethodDescriptor(
  '/teetimes.BookingService/SendPay',
  grpc.web.MethodType.UNARY,
  proto.teetimes.PayRequest,
  proto.teetimes.PayResponse,
  /**
   * @param {!proto.teetimes.PayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teetimes.PayResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teetimes.PayRequest,
 *   !proto.teetimes.PayResponse>}
 */
const methodInfo_BookingService_SendPay = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teetimes.PayResponse,
  /**
   * @param {!proto.teetimes.PayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teetimes.PayResponse.deserializeBinary
);


/**
 * @param {!proto.teetimes.PayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teetimes.PayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teetimes.PayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teetimes.BookingServiceClient.prototype.sendPay =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teetimes.BookingService/SendPay',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendPay,
      callback);
};


/**
 * @param {!proto.teetimes.PayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teetimes.PayResponse>}
 *     Promise that resolves to the response
 */
proto.teetimes.BookingServicePromiseClient.prototype.sendPay =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teetimes.BookingService/SendPay',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendPay);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teetimes.PricesRequest,
 *   !proto.teetimes.PricesResponse>}
 */
const methodDescriptor_BookingService_InitPrices = new grpc.web.MethodDescriptor(
  '/teetimes.BookingService/InitPrices',
  grpc.web.MethodType.UNARY,
  proto.teetimes.PricesRequest,
  proto.teetimes.PricesResponse,
  /**
   * @param {!proto.teetimes.PricesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teetimes.PricesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teetimes.PricesRequest,
 *   !proto.teetimes.PricesResponse>}
 */
const methodInfo_BookingService_InitPrices = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teetimes.PricesResponse,
  /**
   * @param {!proto.teetimes.PricesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teetimes.PricesResponse.deserializeBinary
);


/**
 * @param {!proto.teetimes.PricesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teetimes.PricesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teetimes.PricesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teetimes.BookingServiceClient.prototype.initPrices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teetimes.BookingService/InitPrices',
      request,
      metadata || {},
      methodDescriptor_BookingService_InitPrices,
      callback);
};


/**
 * @param {!proto.teetimes.PricesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teetimes.PricesResponse>}
 *     Promise that resolves to the response
 */
proto.teetimes.BookingServicePromiseClient.prototype.initPrices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teetimes.BookingService/InitPrices',
      request,
      metadata || {},
      methodDescriptor_BookingService_InitPrices);
};


module.exports = proto.teetimes;

