<template>
    <div class="calendar-container">
      <div class="mobile-header">
        <div class="row">
          <div class="col-sm-6-m-head">
            <div style="font-size: x-large;">Forest Hills</div>
          </div>
          <div class="col-sm-6-m-img">
            <img class="img_logo" src="../assets/styles/images/logo1.png" alt="Forest Hills">
          </div>
        </div>
      </div>
      <div class="input-wrapper-head">
        <button type="button" @click="toggleCalendar" class="date-picker-button">
          <span>{{ formattedDate }}</span>
          <span class="arrow-icon">&#9662;</span>
        </button>
      </div>
      <div v-if="showCalendar" class="date-picker-dropdown">
        <div id="calendar">
          <div class="header">
            <button type="button" @click="changeMonth(-1)">‹</button>
            <span>{{ monthName }} {{ year }}</span>
            <button type="button" @click="changeMonth(1)">›</button>
          </div>
          <div class="week-days">
            <div v-for="dayName in weekDayNames" :key="dayName" class="week-day">{{ dayName }}</div>
          </div>
          <div class="days">
            <div 
              v-for="(day,index) in daysOfMonth" 
              :key="day.id" 
              :class="day.classes" 
              ref = "daysmonth"
              @click="selectDay(day,index)">
              {{ day.number }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { CrmServiceClient } from '../crm_service_grpc_web_pb.js';
  import { GetdRequest } from '../crm_service_pb.js';
  export default {
    name: 'DatePicker',
    data() {
      return {
        prevSelected: null,
        currentDate: new Date(),
        selectedDay: null,
        showCalendar: true,
        weekDayNames: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
        disDates: null,
        daysOfMonth: null,
      };
    },
    computed: {
      tday() {
        return this.currentDate.getDate();
      },
      year() {
        return this.currentDate.getFullYear();
      },
      month() {
        return this.currentDate.getMonth();
      },
      monthName() {
        const monthNames = [
          'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
          'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
        ];
        return monthNames[this.month];
      },
      formattedDate() {
        return this.selectedDay 
          ? `${this.selectedDay.getDate()}.${this.selectedDay.getMonth() + 1}.${this.selectedDay.getFullYear()}`
          : 'Выберите дату';
      },
    },
    mounted() {
      this.getDisabledDates()
    },
    methods: {
      getDaysOfMonth() {
        const days = [];
        const firstDay = new Date(this.year, this.month, 1).getDay();
        const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
        const daysInLastMonth = new Date(this.year, this.month, 0).getDate();
        const daysToShowFromLastMonth = firstDay === 0 ? 6 : firstDay - 1;
  
        // Days from previous month
        for (let i = daysToShowFromLastMonth; i > 0; i--) {
          days.push({
            id: `prev-${i}`,
            number: daysInLastMonth - i + 1,
            classes: 'day other-month',
          });
        }
  
        // Days in current month
        for (let i = 1; i <= daysInMonth; i++) {
          let cdayClasses = ' '
          if (i < this.tday && this.month == new Date().getMonth()){
            cdayClasses = 'day other-month'
          }else{
            cdayClasses = 'day current-month'
            this.disDates.forEach(ddate => {
              let spliteDate = ddate.array[0].split('.')
              if (i == spliteDate[0] && this.month+1 == spliteDate[1]){
                cdayClasses = 'day other-month';
              }
            });
          }
          days.push({
            id: `current-${i}`,
            number: i,
            date: new Date(this.year, this.month, i),
            classes: cdayClasses,
          });
          
        }
  
        // Days in next month
        const daysToAddForNextMonth = (7 - days.length % 7) % 7;
        for (let i = 1; i <= daysToAddForNextMonth; i++) {
          days.push({
            id: `next-${i}`,
            number: i,
            classes: 'day other-month',
          });
        }
        this.daysOfMonth = days;
      },
      formatRegDate(date){
        let month = date.getMonth()+1;
        if (month < 10){
          month = "0" + month
        }
        let year = date.getFullYear();
        let format1 = month + "." + year;
        return format1
      },
      getDisabledDates() {
          const client = new CrmServiceClient('https://foresthills.tee-time.ru');
          let request = new GetdRequest();
          request.setRegdate(this.formatRegDate(this.currentDate))
          client.getDisabled(request, {}, (err, response) => {
          if (err) {
              console.error("gRPC error:", err);
          } else {
              this.disDates = response.getDatesList();
              this.getDaysOfMonth()
          }
          });
      },
      toggleCalendar() {
        this.showCalendar = !this.showCalendar;
      },
      changeMonth(step) {
        this.currentDate.setMonth(this.currentDate.getMonth() + step);
        this.currentDate = new Date(this.currentDate); // Обновите currentDate, чтобы Vue отреагировал на изменение
      },
      selectDay(day,index) {
        if (day.classes == 'day other-month'){
          return
        }
        this.selectedDay = day.date;
        this.showCalendar = true;
        this.$refs.daysmonth.forEach(element => {
          element.className = element.className.replace('selected','')
        });
        this.$refs.daysmonth[index].className = 'day current-month selected';
        this.$emit('dateSelected', day.date);
      },
    },
  };
  </script>
  
    
<style scoped>
</style> 