<template>
    <div class="col-sm-6">
        <div class="counter" id="golfCartCounter">
        <span style="width: 60%;">Аренда гольф-кара</span>
        <button type="button" @click="decrementCounter('golfCart')">−</button>
        <span id="oborudcheck1" class="count">{{ counters.golfCart }}</span>
        <button type="button" @click="incrementCounter('golfCart')">+</button>
        </div>
    </div>
    <div class="col-sm-6">
        <div class="counter" id="babyStrollerCounter">
        <span style="width: 60%;">Аренда эл. тележки</span>
        <button type="button" @click="decrementCounter('babyStroller')">−</button>
        <span class="count" id="oborudcheck2">{{ counters.babyStroller }}</span>
        <button type="button" @click="incrementCounter('babyStroller')">+</button>
        </div>
    </div>
    <div class="col-sm-6">
        <div class="counter" id="golfTelCounter">
        <span style="width: 60%;">Аренда гольф-тележки</span>
        <button type="button" @click="decrementCounter('golfTel')">−</button>
        <span class="count" id="oborudcheck3">{{ counters.golfTel }}</span>
        <button type="button" @click="incrementCounter('golfTel')">+</button>
        </div>
    </div>
    <div class="col-sm-6">
        <div class="counter" id="golfBagCounter">
        <span style="width: 60%;">Аренда гольф-бэга</span>
        <button type="button" @click="decrementCounter('golfBag')">−</button>
        <span class="count" id="oborudcheck4">{{ counters.golfBag }}</span>
        <button type="button" @click="incrementCounter('golfBag')">+</button>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    emits: ["obPicked"], // <--- add this line
    setup(_,{ emit }) {
      emit("obPicked")
    },
    data() {
      return {
        counters: {
          golfCart: 0,
          babyStroller: 0,
          golfTel: 0,
          golfBag: 0
        }
      };
    },
    methods: {
      incrementCounter(item) {
        if (this.counters[item] < 4) { // Предположим, что максимальное значение - 4
          this.counters[item]++;
          this.$emit('obPicked',this.counters);

        }
      },
      decrementCounter(item) {
        if (this.counters[item] > 0) {
          this.counters[item]--;
          this.$emit('obPicked',this.counters);

        }
      }
    }
  };
  </script>
  
  <style scoped>

  </style>
  