<template>
    <img class="time-avalible" src="../assets/styles/images/chelik.svg">
</template>

<script>

</script>

<style scoped>
</style>
