import { createWebHistory, createRouter } from "vue-router";
import BookingMain from "@/components/BookingMain.vue";
import EndPage from "@/components/EndPage.vue";

const routes = [
  {
    path: "/",
    name: "Main",
    redirect: '/forrestee' ,
  },
  {
    path: "/forrestee",
    name: "BookingMain",
    component: BookingMain,
  },
  {
    path: "/forrestee/end",
    name: "EndPage",
    component: EndPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;