<template>
    <div class="form-wrapper">
        <div class="input-wrapper">
        <div class="input-icon user"></div>
        <input @input=onChange() v-model="primeName" type="text" id="prime_name" placeholder="Ваше ФИО">
        <span v-if="nameErr" class="error" id="error_name">Введите имя в корректном формате</span>
        </div>            
        <div class="input-wrapper">
        <div class="input-icon phone"></div>
        <input @input=onChange()  v-model="primePhone" type="text" id="prime_phone" placeholder="Ваш номер">
        <span v-if="phoneErr" class="error" id="error_phone">Введите номер в корректном формате</span>
        </div>
        <div class="input-wrapper">
        <div class="input-icon email"></div>
        <input @input=onChange() v-model="primeEmail" type="text" id="prime_email" placeholder="Ваш email">
        <span v-if="emailErr" class="error" id="error_email">Введите корректный email</span>
        </div>          
    </div> 
</template>

<script>
export default {
  name: 'PrimeName',
  props: ['formErrors'],
  data() {
    return {
      primeName: "", // Дата выбрана в DatePicker
      primePhone: "", // Дата выбрана в DatePicker
      primeEmail: "", // Дата выбрана в DatePicker
      errors: null,
      nameErr: false,
      phoneErr: false,
      emailErr: false,
    }
  },
  watch: {
    formErrors(ferrors) {
      this.errors = ferrors;
      this.nameErr = ferrors['nameErr']
      this.phoneErr = ferrors['phoneErr']
      this.emailErr = ferrors['emailErr']
      console.log(this.emailErr);


    }
  },
  methods:{
    onChange(){
      this.$emit('valuesInput',[this.primeName,this.primePhone,this.primeEmail]);
    }
  },
}
</script>
<style scoped>
/* Ваши стили для ContactForm */
</style>
