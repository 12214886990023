
<template>
    <div class="center">
        <div id="myModal" class="modal">
        <div class="modal-content">
            <span class="close">&times;</span>
            <p id="modal-text" style="color: black;">Пожалуйста, выберите дату и время.</p>
        </div>
        </div>  
        <div class="container">
        <div class="formWrapper">      
            <form action="/end" id="myForm">
            <div class="row">
                <div class="col-sm-6-calendar">
                    <DatePicker @dateSelected="updateTimePicker" />
                </div>
                <div class="col-sm-6-time">
                    <TimePicker @timeSelected="getTimeValue" :selected-date="selectedDate" />
                </div>            
            </div>           
            <p class="h2-text">Укажите ваши контактные данные</p>
            <div class="row">
                <PrimeForm @valuesInput="getFormValues" :formErrors="formErrors" />
            </div>   
            <div class="radio" style="padding-block: 2px;">
                <div class="row">
                    <div class="guest-headers">
                    <div class="h2-text">Укажите количество гостей</div>
                    </div>
                    <div class="col-sm-6-radio">
                        <GuestsPicker @guestsPicked="getGuestValue"  />
                    </div>
                </div>
                </div>                    
                <div class="row" id="placetoadd" style="width: 83%;">
                </div>
                <div class="equipment-rental">
                <p class="h2-text">Дополнительное оборудование</p>
                <div class="row">
                    <OborudPicker @obPicked="getObValue"/>
                </div>
                </div>              
                <div class="comment-section">
                    <FooterForm @btn-pressed="getBooking" :bookPrice="priceRub" />
                </div>
            </form>
        </div>
        </div>
    </div>
</template>

<script>
import DatePicker from './DatePicker.vue';
import TimePicker from './TimePicker.vue';
import FooterForm from './FooterForm.vue';
import PrimeForm from './PrimeForm.vue';
import GuestsPicker from './GuestsPicker.vue';
import OborudPicker from './OborudPicker.vue';
import { BookingServiceClient } from '../booking_service_grpc_web_pb.js';
import { BookingRequest,PricesRequest } from '../booking_service_pb.js';
export class Booking {
  constructor(select_date, select_time, id_divtime, prime_name, prime_phone, prime_email, is_member, guest_count, name1, name2, name3, is_car, is_eltel, is_tel, is_bag, comment, summa) {
    this.date = select_date;
    this.time = select_time;
    this.time_id = id_divtime;
    this.name = prime_name;
    this.phone = prime_phone;
    this.email = prime_email;
    this.member = is_member;      
    this.g_count = guest_count;
    this.guest1 = name1;
    this.guest2 = name2;
    this.guest3 = name3;
    this.car = is_car;
    this.el_tel = is_eltel;
    this.tel = is_tel;
    this.bag = is_bag;
    this.comm = comment;
    this.price = summa;
  }
}

export default {
  name: 'BookingPage',
  components: {
    DatePicker,
    TimePicker,
    FooterForm,
    PrimeForm,
    GuestsPicker,
    OborudPicker
  },
  data() {
    return {
      selectedDate: new Date(), // Дата выбрана в DatePicker
      selectTime: null, 
      idDivtime: null, 
      primeName: null, 
      primePhone: null, 
      primeEmail: null, 
      isMember: null, 
      guestCount: 0, 
      nameFirst: null, 
      nameSecond: null, 
      nameThird: null, 
      isCar: null, 
      isEltel: null, 
      isTel: null, 
      isBag: null, 
      commentValue: null, 
      priceRub: null, 
      basePrice: null,
      weekEndPrice: null,
      carprice: null,
      eltelprice: null,
      telprice: null,
      bagprice: null,
      formErrors: {
        'nameErr': false,
        'phoneErr': false,
        'emailErr': false,
        'timeErr': true
      },
    };
  },
  mounted() {
    this.getPrices();
  },
  methods: {
    formatDate: function(date){
        if (date.toLocaleDateString("en-US") != new Date().toLocaleDateString("en-US")){
          this.hours = 1
        }else{
          this.hours = new Date().getHours()
        }
        let day = date.getDate();
        let month = date.getMonth()+1;
        if (month < 10){
          month = "0" + month
        }
        if (day < 10){
          day = "0" + day
        }
        let year = date.getFullYear();
        let format1 = day + "." + month + "." + year;
        return format1
    },
    goToPay(payUrl){
      console.log(payUrl)
      window.location.href = payUrl;
    },
    getPrices() {
        const client = new BookingServiceClient('https://foresthills.tee-time.ru');
        let request = new PricesRequest();
        client.initPrices(request, {}, (err, response) => {
          if (err) {
            console.error("gRPC error:", err);
          } else {
            this.basePrice = response.getBaseprice();
            this.weekEndPrice = response.getWeekendprice();
            this.carprice = response.getCarprice();
            this.eltelprice = response.getEltelprice();
            this.telprice = response.getTelprice();
            this.bagprice = response.getBagprice();
            console.log(response.getBagprice());
          }
        });
    },
    calculateTotal() {
      const basePrice = this.basePrice; // Фиксированная цена для одного гостя
      const weekEndPrice = this.weekEndPrice; // Фиксированная цена для одного гостя
      let price_green = basePrice 

      if (this.isWeekend(this.selectedDate)){
        price_green = weekEndPrice 
      }
      const additionalGuests = this.guestCount || 0;
      // Обновленный расчет стоимости оборудования
      const equipment1Count = this.isCar || 0;
      const equipment2Count = this.isEltel || 0;
      const equipment3Count = this.isTel || 0;
      const equipment4Count = this.isBag || 0;

      const equipmentCost = this.calculateEquipmentCost(equipment1Count, equipment2Count, equipment3Count, equipment4Count);
      const guestCost = additionalGuests * price_green;
      this.priceRub = price_green + guestCost + equipmentCost;
    },
    calculateEquipmentCost(count1, count2, count3, count4) {
      const equipmentPrices = {
          "car": this.carprice,
          "eltel": this.eltelprice,
          "tel": this.telprice,
          "bag": this.bagprice
      };

      let equipmentCost = 0;

      equipmentCost += count1 * equipmentPrices["car"];
      equipmentCost += count2 * equipmentPrices["eltel"];
      equipmentCost += count3 * equipmentPrices["tel"];
      equipmentCost += count4 * equipmentPrices["bag"];

      return equipmentCost;
    },
    isWeekend(dateStr) {
      const dayOfWeek = dateStr.getDay();
      // В JavaScript день недели для воскресенья - это 0, а для субботы - 6
      return dayOfWeek === 0 || dayOfWeek === 6;
    },
    updateTimePicker(values) {
      if (values !== undefined) {
        this.selectedDate = values; 
        this.calculateTotal();
      }
    },
    getTimeValue(values) {
      if (values !== undefined) {
        this.selectTime = values;
      }
    },
    getFormValues(values) {
      if (values !== undefined) {
        this.primeName = values[0];
        this.primePhone = values[1];     
        this.primeEmail = values[2];
        this.calculateTotal();
      }
    },
    getGuestValue(value) {
      this.guestCount = value;
      this.calculateTotal();
    },
    getObValue(values){
      if (values !== undefined) {
        this.isCar = values.golfCart;
        this.isBag = values.golfBag;
        this.isEltel = values.babyStroller;
        this.isTel = values.golfTel;
        this.calculateTotal();
      }
    },
    getBooking(values){
        let errors = {
          'nameErr': false,
          'phoneErr': false,
          'emailErr': false,
        }
        this.calculateTotal();
        this.commentValue = values;
        if (!this.primeName || this.primeName.length < 5) {
          errors["nameErr"] = true;
          console.log("nameErr");

        }
        var phonePattern = /^\+?[1-9]\d{1,14}$/; // Пример простого паттерна для международного номера
        if (!this.primeName || !this.primePhone.match(phonePattern)) {
          errors["phoneErr"] = true;
          console.log("phoneErr");

        }
        if (!this.primeEmail || !this.primeEmail.match(/^[^@]+@[^@]+\.[^@]+$/) ) {
          errors["emailErr"] = true;
          console.log("emailErr");
        } 
        this.formErrors = errors;
        console.log(this.formErrors);
        if (errors["emailErr"] != true && errors["nameErr"] != true && errors["phoneErr"] != true){
          const myBooking = new Booking(
            this.formatDate(this.selectedDate),
            this.selectTime, 
            this.idDivtime, 
            this.primeName, 
            this.primePhone, 
            this.primeEmail, 
            false, 
            this.guestCount, 
            "this.nameFirst", 
            "this.nameSecond", 
            "this.nameThird", 
            this.isCar, 
            this.isEltel, 
            this.isTel, 
            this.isBag, 
            this.commentValue, 
            this.priceRub
          );
          console.log(myBooking);
          this.sendBooking(myBooking)
        }
    },
    sendBooking(book){
      const client = new BookingServiceClient('https://foresthills.tee-time.ru');
        let request = new BookingRequest();
        request.setSelecteddate(book.date);
        request.setSelecttime(book.time);
        request.setIddivtime(book.time_id);
        request.setPrimename(book.name);
        request.setPrimephone(book.phone);
        request.setPrimeemail(book.email);
        request.setIsmember(book.member);
        request.setGuestcount(book.g_count);
        request.setNamefirst(book.guest1);
        request.setNamesecond(book.guest2);
        request.setNamethird(book.guest3);
        request.setIscar(book.car);
        request.setIseltel(book.el_tel);
        request.setIstel(book.tel);
        request.setIsbag(book.bag);
        request.setCommentvalue(book.comm);
        request.setPricerub(book.price);
        client.sendBooking(request, {}, (err, response) => {
          if (err) {
            console.error("gRPC error:", err);
          } else {
            this.goToPay(response.getBookid());
          }
        });
    }
  }
}
</script>

<style scoped>

</style>


