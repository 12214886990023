<template>
    <div class="container">

    <div style="
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    ">
        <div class="conr">
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" class="animate">
                <path d="M 18 32.34 l -8.34 -8.34 -2.83 2.83 11.17 11.17 24 -24 -2.83 -2.83 z" stroke="#3da35a" fill="transparent"></path>
            </svg>
            <div style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align:center;
            align-items: center;"><h1>Спасибо!</h1><p style="font-size:20px;text-shadow:0 0 #00000066;">Ваш ти-тайм успешно забронирован, с вами свяжутся для подтверждения</p></div>
        </div>
    </div>

    </div>
  </template>
  
  <script>
  export default {
    el: 'EndPage',
    name: "EndPage",
  }
  </script>

<style>   
  svg {
    width: 200px;
    height: 100px;
  }
  
  path {
    stroke-dasharray: 99.47578430175781;
    stroke-dashoffset: -99.47578430175781;
    fill: transparent;
  }
  
  svg.animate path {
    animation: 1.7s ease forwards draw;
    opacity:1;
  }
  .conr {
        border-radius: 40px;
        background: linear-gradient(180deg, rgba(181, 192, 205, 0.40) 9.35%, rgb(229 229 229 / 45%) 56.01%, rgb(78 128 70 / 56%) 99.79%);
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
        width: 50%;
        height: auto;
        color: #222;
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }
  @keyframes draw {
    0% {
      opacity: 1;
      stroke-dashoffset: -99.47578430175781;
      fill: transparent;
      transform: translateY(0);
    }
    
    35% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    
    60% {
      fill: #3da35a;
      opacity: 1;
      transform: translateY(0);
    }
    
    100% {
      fill: #3da35a;
      stroke-dashoffset: 0;
      opacity: 1; /* Задаём непрозрачность 1, чтобы элемент оставался видимым */
      transform: translateY(0); /* Убираем перемещение, чтобы элемент оставался на месте */
    }
  }
  @media (max-width: 767px) {
    .conr{
        border-radius: 40px;
        background: linear-gradient(180deg, rgba(181, 192, 205, 0.40) 9.35%, rgb(229 229 229 / 45%) 56.01%, rgb(78 128 70 / 56%) 99.79%);    backdrop-filter: blur(6px);
        width: 100%;
        height: auto;
        color: #222;
        padding: 20px 10px;
      }
}
  
  
  
  
</style>