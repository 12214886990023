// source: booking_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.teetimes.AvailableRequest', null, global);
goog.exportSymbol('proto.teetimes.AvailableResponse', null, global);
goog.exportSymbol('proto.teetimes.BookingRequest', null, global);
goog.exportSymbol('proto.teetimes.BookingResponse', null, global);
goog.exportSymbol('proto.teetimes.PayRequest', null, global);
goog.exportSymbol('proto.teetimes.PayResponse', null, global);
goog.exportSymbol('proto.teetimes.PricesRequest', null, global);
goog.exportSymbol('proto.teetimes.PricesResponse', null, global);
goog.exportSymbol('proto.teetimes.TimeSlot', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teetimes.AvailableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teetimes.AvailableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teetimes.AvailableRequest.displayName = 'proto.teetimes.AvailableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teetimes.AvailableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teetimes.AvailableResponse.repeatedFields_, null);
};
goog.inherits(proto.teetimes.AvailableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teetimes.AvailableResponse.displayName = 'proto.teetimes.AvailableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teetimes.TimeSlot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teetimes.TimeSlot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teetimes.TimeSlot.displayName = 'proto.teetimes.TimeSlot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teetimes.BookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teetimes.BookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teetimes.BookingRequest.displayName = 'proto.teetimes.BookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teetimes.BookingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teetimes.BookingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teetimes.BookingResponse.displayName = 'proto.teetimes.BookingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teetimes.PayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teetimes.PayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teetimes.PayRequest.displayName = 'proto.teetimes.PayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teetimes.PayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teetimes.PayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teetimes.PayResponse.displayName = 'proto.teetimes.PayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teetimes.PricesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teetimes.PricesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teetimes.PricesRequest.displayName = 'proto.teetimes.PricesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teetimes.PricesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teetimes.PricesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teetimes.PricesResponse.displayName = 'proto.teetimes.PricesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teetimes.AvailableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teetimes.AvailableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teetimes.AvailableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.AvailableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teetimes.AvailableRequest}
 */
proto.teetimes.AvailableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teetimes.AvailableRequest;
  return proto.teetimes.AvailableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teetimes.AvailableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teetimes.AvailableRequest}
 */
proto.teetimes.AvailableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teetimes.AvailableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teetimes.AvailableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teetimes.AvailableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.AvailableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInterval();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string selected_date = 1;
 * @return {string}
 */
proto.teetimes.AvailableRequest.prototype.getSelectedDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.AvailableRequest} returns this
 */
proto.teetimes.AvailableRequest.prototype.setSelectedDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string interval = 2;
 * @return {string}
 */
proto.teetimes.AvailableRequest.prototype.getInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.AvailableRequest} returns this
 */
proto.teetimes.AvailableRequest.prototype.setInterval = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teetimes.AvailableResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teetimes.AvailableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.teetimes.AvailableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teetimes.AvailableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.AvailableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeSlotsList: jspb.Message.toObjectList(msg.getTimeSlotsList(),
    proto.teetimes.TimeSlot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teetimes.AvailableResponse}
 */
proto.teetimes.AvailableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teetimes.AvailableResponse;
  return proto.teetimes.AvailableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teetimes.AvailableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teetimes.AvailableResponse}
 */
proto.teetimes.AvailableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teetimes.TimeSlot;
      reader.readMessage(value,proto.teetimes.TimeSlot.deserializeBinaryFromReader);
      msg.addTimeSlots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teetimes.AvailableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teetimes.AvailableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teetimes.AvailableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.AvailableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeSlotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.teetimes.TimeSlot.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TimeSlot time_slots = 1;
 * @return {!Array<!proto.teetimes.TimeSlot>}
 */
proto.teetimes.AvailableResponse.prototype.getTimeSlotsList = function() {
  return /** @type{!Array<!proto.teetimes.TimeSlot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teetimes.TimeSlot, 1));
};


/**
 * @param {!Array<!proto.teetimes.TimeSlot>} value
 * @return {!proto.teetimes.AvailableResponse} returns this
*/
proto.teetimes.AvailableResponse.prototype.setTimeSlotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.teetimes.TimeSlot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teetimes.TimeSlot}
 */
proto.teetimes.AvailableResponse.prototype.addTimeSlots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.teetimes.TimeSlot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teetimes.AvailableResponse} returns this
 */
proto.teetimes.AvailableResponse.prototype.clearTimeSlotsList = function() {
  return this.setTimeSlotsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teetimes.TimeSlot.prototype.toObject = function(opt_includeInstance) {
  return proto.teetimes.TimeSlot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teetimes.TimeSlot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.TimeSlot.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, ""),
    val: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teetimes.TimeSlot}
 */
proto.teetimes.TimeSlot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teetimes.TimeSlot;
  return proto.teetimes.TimeSlot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teetimes.TimeSlot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teetimes.TimeSlot}
 */
proto.teetimes.TimeSlot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teetimes.TimeSlot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teetimes.TimeSlot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teetimes.TimeSlot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.TimeSlot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string time = 1;
 * @return {string}
 */
proto.teetimes.TimeSlot.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.TimeSlot} returns this
 */
proto.teetimes.TimeSlot.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 val = 2;
 * @return {number}
 */
proto.teetimes.TimeSlot.prototype.getVal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.TimeSlot} returns this
 */
proto.teetimes.TimeSlot.prototype.setVal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teetimes.BookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teetimes.BookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teetimes.BookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.BookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    selecteddate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    selecttime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iddivtime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    primename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    primephone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    primeemail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ismember: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    guestcount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    namefirst: jspb.Message.getFieldWithDefault(msg, 9, ""),
    namesecond: jspb.Message.getFieldWithDefault(msg, 10, ""),
    namethird: jspb.Message.getFieldWithDefault(msg, 11, ""),
    iscar: jspb.Message.getFieldWithDefault(msg, 12, 0),
    iseltel: jspb.Message.getFieldWithDefault(msg, 13, 0),
    istel: jspb.Message.getFieldWithDefault(msg, 14, 0),
    isbag: jspb.Message.getFieldWithDefault(msg, 15, 0),
    commentvalue: jspb.Message.getFieldWithDefault(msg, 16, ""),
    pricerub: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teetimes.BookingRequest}
 */
proto.teetimes.BookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teetimes.BookingRequest;
  return proto.teetimes.BookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teetimes.BookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teetimes.BookingRequest}
 */
proto.teetimes.BookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelecteddate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelecttime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIddivtime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimename(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimephone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimeemail(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsmember(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGuestcount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamefirst(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamesecond(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamethird(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIscar(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIseltel(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIstel(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIsbag(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentvalue(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPricerub(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teetimes.BookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teetimes.BookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teetimes.BookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.BookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelecteddate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSelecttime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIddivtime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrimename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrimephone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrimeemail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsmember();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getGuestcount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getNamefirst();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getNamesecond();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNamethird();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIscar();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getIseltel();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getIstel();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getIsbag();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getCommentvalue();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPricerub();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * optional string selectedDate = 1;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getSelecteddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setSelecteddate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string selectTime = 2;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getSelecttime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setSelecttime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string idDivtime = 3;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getIddivtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setIddivtime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string primeName = 4;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getPrimename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setPrimename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string primePhone = 5;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getPrimephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setPrimephone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string primeEmail = 6;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getPrimeemail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setPrimeemail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool isMember = 7;
 * @return {boolean}
 */
proto.teetimes.BookingRequest.prototype.getIsmember = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setIsmember = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 guestCount = 8;
 * @return {number}
 */
proto.teetimes.BookingRequest.prototype.getGuestcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setGuestcount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string nameFirst = 9;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getNamefirst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setNamefirst = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string nameSecond = 10;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getNamesecond = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setNamesecond = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string nameThird = 11;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getNamethird = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setNamethird = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 isCar = 12;
 * @return {number}
 */
proto.teetimes.BookingRequest.prototype.getIscar = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setIscar = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 isEltel = 13;
 * @return {number}
 */
proto.teetimes.BookingRequest.prototype.getIseltel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setIseltel = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 isTel = 14;
 * @return {number}
 */
proto.teetimes.BookingRequest.prototype.getIstel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setIstel = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 isBag = 15;
 * @return {number}
 */
proto.teetimes.BookingRequest.prototype.getIsbag = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setIsbag = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string commentValue = 16;
 * @return {string}
 */
proto.teetimes.BookingRequest.prototype.getCommentvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setCommentvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int32 priceRub = 17;
 * @return {number}
 */
proto.teetimes.BookingRequest.prototype.getPricerub = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.BookingRequest} returns this
 */
proto.teetimes.BookingRequest.prototype.setPricerub = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teetimes.BookingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.teetimes.BookingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teetimes.BookingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.BookingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teetimes.BookingResponse}
 */
proto.teetimes.BookingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teetimes.BookingResponse;
  return proto.teetimes.BookingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teetimes.BookingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teetimes.BookingResponse}
 */
proto.teetimes.BookingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teetimes.BookingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teetimes.BookingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teetimes.BookingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.BookingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string bookId = 1;
 * @return {string}
 */
proto.teetimes.BookingResponse.prototype.getBookid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.BookingResponse} returns this
 */
proto.teetimes.BookingResponse.prototype.setBookid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teetimes.PayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teetimes.PayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teetimes.PayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.PayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    payid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teetimes.PayRequest}
 */
proto.teetimes.PayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teetimes.PayRequest;
  return proto.teetimes.PayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teetimes.PayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teetimes.PayRequest}
 */
proto.teetimes.PayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teetimes.PayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teetimes.PayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teetimes.PayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.PayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payId = 1;
 * @return {string}
 */
proto.teetimes.PayRequest.prototype.getPayid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.PayRequest} returns this
 */
proto.teetimes.PayRequest.prototype.setPayid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teetimes.PayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.teetimes.PayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teetimes.PayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.PayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paystate: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teetimes.PayResponse}
 */
proto.teetimes.PayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teetimes.PayResponse;
  return proto.teetimes.PayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teetimes.PayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teetimes.PayResponse}
 */
proto.teetimes.PayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaystate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teetimes.PayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teetimes.PayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teetimes.PayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.PayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaystate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payState = 1;
 * @return {string}
 */
proto.teetimes.PayResponse.prototype.getPaystate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teetimes.PayResponse} returns this
 */
proto.teetimes.PayResponse.prototype.setPaystate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teetimes.PricesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teetimes.PricesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teetimes.PricesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.PricesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teetimes.PricesRequest}
 */
proto.teetimes.PricesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teetimes.PricesRequest;
  return proto.teetimes.PricesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teetimes.PricesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teetimes.PricesRequest}
 */
proto.teetimes.PricesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teetimes.PricesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teetimes.PricesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teetimes.PricesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.PricesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teetimes.PricesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.teetimes.PricesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teetimes.PricesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.PricesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseprice: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weekendprice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    carprice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eltelprice: jspb.Message.getFieldWithDefault(msg, 4, 0),
    telprice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bagprice: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teetimes.PricesResponse}
 */
proto.teetimes.PricesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teetimes.PricesResponse;
  return proto.teetimes.PricesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teetimes.PricesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teetimes.PricesResponse}
 */
proto.teetimes.PricesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBaseprice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeekendprice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCarprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEltelprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTelprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBagprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teetimes.PricesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teetimes.PricesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teetimes.PricesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teetimes.PricesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseprice();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWeekendprice();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCarprice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getEltelprice();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTelprice();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getBagprice();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 basePrice = 1;
 * @return {number}
 */
proto.teetimes.PricesResponse.prototype.getBaseprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.PricesResponse} returns this
 */
proto.teetimes.PricesResponse.prototype.setBaseprice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 weekEndPrice = 2;
 * @return {number}
 */
proto.teetimes.PricesResponse.prototype.getWeekendprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.PricesResponse} returns this
 */
proto.teetimes.PricesResponse.prototype.setWeekendprice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 carprice = 3;
 * @return {number}
 */
proto.teetimes.PricesResponse.prototype.getCarprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.PricesResponse} returns this
 */
proto.teetimes.PricesResponse.prototype.setCarprice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 eltelprice = 4;
 * @return {number}
 */
proto.teetimes.PricesResponse.prototype.getEltelprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.PricesResponse} returns this
 */
proto.teetimes.PricesResponse.prototype.setEltelprice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 telprice = 5;
 * @return {number}
 */
proto.teetimes.PricesResponse.prototype.getTelprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.PricesResponse} returns this
 */
proto.teetimes.PricesResponse.prototype.setTelprice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 bagprice = 6;
 * @return {number}
 */
proto.teetimes.PricesResponse.prototype.getBagprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.teetimes.PricesResponse} returns this
 */
proto.teetimes.PricesResponse.prototype.setBagprice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


goog.object.extend(exports, proto.teetimes);
