/**
 * @fileoverview gRPC-Web generated client stub for crm
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.crm = require('./crm_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.crm.CrmServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.crm.CrmServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.SlotsRequest,
 *   !proto.crm.SlotsResponse>}
 */
const methodDescriptor_CrmService_GetSlots = new grpc.web.MethodDescriptor(
  '/crm.CrmService/GetSlots',
  grpc.web.MethodType.UNARY,
  proto.crm.SlotsRequest,
  proto.crm.SlotsResponse,
  /**
   * @param {!proto.crm.SlotsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.SlotsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.SlotsRequest,
 *   !proto.crm.SlotsResponse>}
 */
const methodInfo_CrmService_GetSlots = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.SlotsResponse,
  /**
   * @param {!proto.crm.SlotsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.SlotsResponse.deserializeBinary
);


/**
 * @param {!proto.crm.SlotsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.SlotsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.SlotsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.getSlots =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/GetSlots',
      request,
      metadata || {},
      methodDescriptor_CrmService_GetSlots,
      callback);
};


/**
 * @param {!proto.crm.SlotsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.SlotsResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.getSlots =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/GetSlots',
      request,
      metadata || {},
      methodDescriptor_CrmService_GetSlots);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.BookRequest,
 *   !proto.crm.BookResponse>}
 */
const methodDescriptor_CrmService_GetBook = new grpc.web.MethodDescriptor(
  '/crm.CrmService/GetBook',
  grpc.web.MethodType.UNARY,
  proto.crm.BookRequest,
  proto.crm.BookResponse,
  /**
   * @param {!proto.crm.BookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.BookResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.BookRequest,
 *   !proto.crm.BookResponse>}
 */
const methodInfo_CrmService_GetBook = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.BookResponse,
  /**
   * @param {!proto.crm.BookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.BookResponse.deserializeBinary
);


/**
 * @param {!proto.crm.BookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.BookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.BookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.getBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/GetBook',
      request,
      metadata || {},
      methodDescriptor_CrmService_GetBook,
      callback);
};


/**
 * @param {!proto.crm.BookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.BookResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.getBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/GetBook',
      request,
      metadata || {},
      methodDescriptor_CrmService_GetBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.CommRequest,
 *   !proto.crm.CommResponse>}
 */
const methodDescriptor_CrmService_PostComm = new grpc.web.MethodDescriptor(
  '/crm.CrmService/PostComm',
  grpc.web.MethodType.UNARY,
  proto.crm.CommRequest,
  proto.crm.CommResponse,
  /**
   * @param {!proto.crm.CommRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.CommResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.CommRequest,
 *   !proto.crm.CommResponse>}
 */
const methodInfo_CrmService_PostComm = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.CommResponse,
  /**
   * @param {!proto.crm.CommRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.CommResponse.deserializeBinary
);


/**
 * @param {!proto.crm.CommRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.CommResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.CommResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.postComm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/PostComm',
      request,
      metadata || {},
      methodDescriptor_CrmService_PostComm,
      callback);
};


/**
 * @param {!proto.crm.CommRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.CommResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.postComm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/PostComm',
      request,
      metadata || {},
      methodDescriptor_CrmService_PostComm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.SearchRequest,
 *   !proto.crm.SearchResponse>}
 */
const methodDescriptor_CrmService_SearchBook = new grpc.web.MethodDescriptor(
  '/crm.CrmService/SearchBook',
  grpc.web.MethodType.UNARY,
  proto.crm.SearchRequest,
  proto.crm.SearchResponse,
  /**
   * @param {!proto.crm.SearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.SearchResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.SearchRequest,
 *   !proto.crm.SearchResponse>}
 */
const methodInfo_CrmService_SearchBook = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.SearchResponse,
  /**
   * @param {!proto.crm.SearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.SearchResponse.deserializeBinary
);


/**
 * @param {!proto.crm.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.SearchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.SearchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.searchBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/SearchBook',
      request,
      metadata || {},
      methodDescriptor_CrmService_SearchBook,
      callback);
};


/**
 * @param {!proto.crm.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.SearchResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.searchBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/SearchBook',
      request,
      metadata || {},
      methodDescriptor_CrmService_SearchBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.RegisterRequest,
 *   !proto.crm.RegisterResponse>}
 */
const methodDescriptor_CrmService_RegisterUser = new grpc.web.MethodDescriptor(
  '/crm.CrmService/RegisterUser',
  grpc.web.MethodType.UNARY,
  proto.crm.RegisterRequest,
  proto.crm.RegisterResponse,
  /**
   * @param {!proto.crm.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.RegisterResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.RegisterRequest,
 *   !proto.crm.RegisterResponse>}
 */
const methodInfo_CrmService_RegisterUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.RegisterResponse,
  /**
   * @param {!proto.crm.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.crm.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.registerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/RegisterUser',
      request,
      metadata || {},
      methodDescriptor_CrmService_RegisterUser,
      callback);
};


/**
 * @param {!proto.crm.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.registerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/RegisterUser',
      request,
      metadata || {},
      methodDescriptor_CrmService_RegisterUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.AuthenticateRequest,
 *   !proto.crm.AuthenticateResponse>}
 */
const methodDescriptor_CrmService_AuthenticateUser = new grpc.web.MethodDescriptor(
  '/crm.CrmService/AuthenticateUser',
  grpc.web.MethodType.UNARY,
  proto.crm.AuthenticateRequest,
  proto.crm.AuthenticateResponse,
  /**
   * @param {!proto.crm.AuthenticateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.AuthenticateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.AuthenticateRequest,
 *   !proto.crm.AuthenticateResponse>}
 */
const methodInfo_CrmService_AuthenticateUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.AuthenticateResponse,
  /**
   * @param {!proto.crm.AuthenticateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.AuthenticateResponse.deserializeBinary
);


/**
 * @param {!proto.crm.AuthenticateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.AuthenticateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.AuthenticateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.authenticateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/AuthenticateUser',
      request,
      metadata || {},
      methodDescriptor_CrmService_AuthenticateUser,
      callback);
};


/**
 * @param {!proto.crm.AuthenticateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.AuthenticateResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.authenticateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/AuthenticateUser',
      request,
      metadata || {},
      methodDescriptor_CrmService_AuthenticateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.RefundRequest,
 *   !proto.crm.RefundResponse>}
 */
const methodDescriptor_CrmService_PostRefund = new grpc.web.MethodDescriptor(
  '/crm.CrmService/PostRefund',
  grpc.web.MethodType.UNARY,
  proto.crm.RefundRequest,
  proto.crm.RefundResponse,
  /**
   * @param {!proto.crm.RefundRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.RefundResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.RefundRequest,
 *   !proto.crm.RefundResponse>}
 */
const methodInfo_CrmService_PostRefund = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.RefundResponse,
  /**
   * @param {!proto.crm.RefundRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.RefundResponse.deserializeBinary
);


/**
 * @param {!proto.crm.RefundRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.RefundResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.RefundResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.postRefund =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/PostRefund',
      request,
      metadata || {},
      methodDescriptor_CrmService_PostRefund,
      callback);
};


/**
 * @param {!proto.crm.RefundRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.RefundResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.postRefund =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/PostRefund',
      request,
      metadata || {},
      methodDescriptor_CrmService_PostRefund);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.DisableRequest,
 *   !proto.crm.DisableResponse>}
 */
const methodDescriptor_CrmService_DisableDates = new grpc.web.MethodDescriptor(
  '/crm.CrmService/DisableDates',
  grpc.web.MethodType.UNARY,
  proto.crm.DisableRequest,
  proto.crm.DisableResponse,
  /**
   * @param {!proto.crm.DisableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.DisableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.DisableRequest,
 *   !proto.crm.DisableResponse>}
 */
const methodInfo_CrmService_DisableDates = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.DisableResponse,
  /**
   * @param {!proto.crm.DisableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.DisableResponse.deserializeBinary
);


/**
 * @param {!proto.crm.DisableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.DisableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.DisableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.disableDates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/DisableDates',
      request,
      metadata || {},
      methodDescriptor_CrmService_DisableDates,
      callback);
};


/**
 * @param {!proto.crm.DisableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.DisableResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.disableDates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/DisableDates',
      request,
      metadata || {},
      methodDescriptor_CrmService_DisableDates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.GetdRequest,
 *   !proto.crm.GetdResponse>}
 */
const methodDescriptor_CrmService_GetDisabled = new grpc.web.MethodDescriptor(
  '/crm.CrmService/GetDisabled',
  grpc.web.MethodType.UNARY,
  proto.crm.GetdRequest,
  proto.crm.GetdResponse,
  /**
   * @param {!proto.crm.GetdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.GetdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.GetdRequest,
 *   !proto.crm.GetdResponse>}
 */
const methodInfo_CrmService_GetDisabled = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.GetdResponse,
  /**
   * @param {!proto.crm.GetdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.GetdResponse.deserializeBinary
);


/**
 * @param {!proto.crm.GetdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.GetdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.GetdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.getDisabled =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/GetDisabled',
      request,
      metadata || {},
      methodDescriptor_CrmService_GetDisabled,
      callback);
};


/**
 * @param {!proto.crm.GetdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.GetdResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.getDisabled =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/GetDisabled',
      request,
      metadata || {},
      methodDescriptor_CrmService_GetDisabled);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.GetTimesRequest,
 *   !proto.crm.GetTimesResponse>}
 */
const methodDescriptor_CrmService_GetTimesVal = new grpc.web.MethodDescriptor(
  '/crm.CrmService/GetTimesVal',
  grpc.web.MethodType.UNARY,
  proto.crm.GetTimesRequest,
  proto.crm.GetTimesResponse,
  /**
   * @param {!proto.crm.GetTimesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.GetTimesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.GetTimesRequest,
 *   !proto.crm.GetTimesResponse>}
 */
const methodInfo_CrmService_GetTimesVal = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.GetTimesResponse,
  /**
   * @param {!proto.crm.GetTimesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.GetTimesResponse.deserializeBinary
);


/**
 * @param {!proto.crm.GetTimesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.GetTimesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.GetTimesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.getTimesVal =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/GetTimesVal',
      request,
      metadata || {},
      methodDescriptor_CrmService_GetTimesVal,
      callback);
};


/**
 * @param {!proto.crm.GetTimesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.GetTimesResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.getTimesVal =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/GetTimesVal',
      request,
      metadata || {},
      methodDescriptor_CrmService_GetTimesVal);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.crm.UpdateRequest,
 *   !proto.crm.UpdateResponse>}
 */
const methodDescriptor_CrmService_UpdateBooking = new grpc.web.MethodDescriptor(
  '/crm.CrmService/UpdateBooking',
  grpc.web.MethodType.UNARY,
  proto.crm.UpdateRequest,
  proto.crm.UpdateResponse,
  /**
   * @param {!proto.crm.UpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.UpdateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.crm.UpdateRequest,
 *   !proto.crm.UpdateResponse>}
 */
const methodInfo_CrmService_UpdateBooking = new grpc.web.AbstractClientBase.MethodInfo(
  proto.crm.UpdateResponse,
  /**
   * @param {!proto.crm.UpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.crm.UpdateResponse.deserializeBinary
);


/**
 * @param {!proto.crm.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.crm.UpdateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.crm.UpdateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.crm.CrmServiceClient.prototype.updateBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/crm.CrmService/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_CrmService_UpdateBooking,
      callback);
};


/**
 * @param {!proto.crm.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.crm.UpdateResponse>}
 *     Promise that resolves to the response
 */
proto.crm.CrmServicePromiseClient.prototype.updateBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/crm.CrmService/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_CrmService_UpdateBooking);
};


module.exports = proto.crm;

